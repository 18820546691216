import { configureStore } from '@reduxjs/toolkit';

import CustomizerReducer from './customizer/CustomizerSlice';
import CallCenterReducer from './callCenter/callCenterSlice';
import GlobalsReducer from './globals/globalsSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    callCenter: CallCenterReducer,
    globals: GlobalsReducer,
  },
});

export default store;
