import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";

//Projeto
const CallCenter = Loadable(
  lazy(() => import("../views/CallCenter/CallCenter"))
);
const Dashboard = Loadable(lazy(() => import("../views/Dashboard")));
const Profile = Loadable(lazy(() => import("../views/Profile/Profile")));
const Companies = Loadable(lazy(() => import("../views/Companies/Companies")));
const CreateEditCompany = Loadable(lazy(() => import("../views/Companies/CreateEditCompany/CreateEditCompanyTabs")));
const CompaniesTestimonials = Loadable(lazy(() => import("../views/Companies/Testimonials")));
const CompaniesStatistics = Loadable(lazy(() => import("../views/Companies/Statistics")));
const Segements = Loadable(lazy(() => import("../views/Segments/Segments")));
const CreateEditSegment = Loadable(lazy(() => import("../views/Segments/CreateEditSegment")));
const Users = Loadable(lazy(() => import("../views/Users/Users")));
const CreateEditUsers = Loadable(lazy(() => import("../views/Users/CreateEditUser")));
const DetailUsers = Loadable(lazy(() => import("../views/Users/DetailUser")));
const Jobs = Loadable(lazy(() => import("../views/Jobs/Jobs")));
const CreateEditJobs = Loadable(lazy(() => import("../views/Jobs/CreateEditJob")));
const DetailJobs = Loadable(lazy(() => import("../views/Jobs/DetailJob")));
const RealStates = Loadable(lazy(() => import("../views/RealStates/RealStates")));
const CreateEditRealStates = Loadable(lazy(() => import("../views/RealStates/CreateEditRealState")));
const DetailRealStates = Loadable(lazy(() => import("../views/RealStates/DetailRealState")));
const CategoryRealStates = Loadable(lazy(() => import("../views/RealStates/CategoryRealState")));
const CreateEditRealStatesCategory = Loadable(lazy(() => import("../views/RealStates/CreateEditCategory")));
const Vehicles = Loadable(lazy(() => import("../views/Vehicles/Vehicles")));
const CreateEditVehicles = Loadable(lazy(() => import("../views/Vehicles/CreateEditVehicle")));
const DetailVehicles = Loadable(lazy(() => import("../views/Vehicles/DetailVehicle")));
const News = Loadable(lazy(() => import("../views/News/News")));
const CreateEditNews = Loadable(lazy(() => import("../views/News/CreateEditNews")));
const DetailNews = Loadable(lazy(() => import("../views/News/DetailNews")));
const Events = Loadable(lazy(() => import("../views/Events/Events")));
const CreateEditEvents = Loadable(lazy(() => import("../views/Events/CreateEditEvent")));
const DetailEvents = Loadable(lazy(() => import("../views/Events/DetailEvent")));
const Courses = Loadable(lazy(() => import("../views/Courses/Courses")));
const CreateEditCourses = Loadable(lazy(() => import("../views/Courses/CreateEditCourse")));
const CategoryCourses = Loadable(lazy(() => import("../views/Courses/CategoryCourse")));
const CreateEditCoursesCategory = Loadable(lazy(() => import("../views/Courses/CreateEditCategory")));
const DetailCourses = Loadable(lazy(() => import("../views/Courses/DetailCourse")));
const Products = Loadable(lazy(() => import("../views/Products/Products")));
const CreateEditProducts = Loadable(lazy(() => import("../views/Products/CreateEditProduct")));
const DetailProducts = Loadable(lazy(() => import("../views/Products/DetailProducts")));
const Services = Loadable(lazy(() => import("../views/Services/Services")));
const CreateEditServices = Loadable(lazy(() => import("../views/Services/CreateEditService")));
const DetailServices = Loadable(lazy(() => import("../views/Services/DetailServices")));
const Offers = Loadable(lazy(() => import("../views/Offers/Offers")));
const OffersCoupons = Loadable(lazy(() => import("../views/Offers/Coupons")));
const CreateEditOffers = Loadable(lazy(() => import("../views/Offers/CreateEditOffer")));
const DetailOffers = Loadable(lazy(() => import("../views/Offers/DetailOffer")));
const Posts = Loadable(lazy(() => import("../views/Posts/Posts")));
const CreateEditPosts = Loadable(lazy(() => import("../views/Posts/CreateEditPost")));
const DetailPosts = Loadable(lazy(() => import("../views/Posts/DetailPost")));
const Highlights = Loadable(lazy(() => import("../views/Highlights/Highlights")));
const CreateEditHighlights = Loadable(lazy(() => import("../views/Highlights/CreateEditHighlight")));
const DetailHighlights = Loadable(lazy(() => import("../views/Highlights/DetailHighlight")));
const Interests = Loadable(lazy(() => import("../views/Interests/Interests")));
const CreateEditInterests = Loadable(lazy(() => import("../views/Interests/CreateEditInterest")));
const MyBusiness = Loadable(lazy(() => import("../views/MyBusiness/MyBusiness")));
const Pages = Loadable(lazy(() => import("../views/Pages/Pages")));
const CreateEditPages = Loadable(lazy(() => import("../views/Pages/CreateEditPage")));
const Banners = Loadable(lazy(() => import("../views/Banners/Banners")));
const CreateEditBanners = Loadable(lazy(() => import("../views/Banners/CreateEditBanner")));
const Walls = Loadable(lazy(() => import("../views/Walls/Walls")));
const CreateEditWalls = Loadable(lazy(() => import("../views/Walls/CreateEditWall")));
const DetailWalls = Loadable(lazy(() => import("../views/Walls/DetailWall")));
const Admins = Loadable(lazy(() => import("../views/Administrators/Administrators")));
const CreateEditAdmins = Loadable(lazy(() => import("../views/Administrators/CreateEditAdministrator")));
const DetailAdmins = Loadable(lazy(() => import("../views/Administrators/DetailAdministrator")));
const Cities = Loadable(lazy(() => import("../views/Cities/Cities")));
const CreateCities = Loadable(lazy(() => import("../views/Cities/CreateCity")));
const States = Loadable(lazy(() => import("../views/States/States")));
const CreateStates = Loadable(lazy(() => import("../views/States/CreateState")));
const Leads = Loadable(lazy(() => import("../views/Leads/Leads")));
const DetailLeads = Loadable(lazy(() => import("../views/Leads/DetailLead")));
const EditLeads = Loadable(lazy(() => import("../views/Leads/EditLeads")));
const Forms = Loadable(lazy(() => import("../views/Forms/Forms")));
const CreateEditForms = Loadable(lazy(() => import("../views/Forms/CreateEditForm")));
const Dictionary = Loadable(lazy(() => import("../views/Dictionary/Dictionary")));
const CreateEditDictionary = Loadable(lazy(() => import("../views/Dictionary/CreateEditDictionary")));
const Lotteries = Loadable(lazy(() => import("../views/Lotteries/Lotteries")));
const CreateEditLotteries = Loadable(lazy(() => import("../views/Lotteries/CreateEditLotteries")));
const BioLinks = Loadable(lazy(() => import("../views/BioLinks/BioLinks")));
const CreateEditBioLinks = Loadable(lazy(() => import("../views/BioLinks/CreateEditBioLink")));
const RelatoriesCallCenter = Loadable(lazy(() => import("../views/Relatories/CallCenter/RelatoriesCallCenter")));
const RelatoriesQuote = Loadable(lazy(() => import("../views/Relatories/CallCenter/RelatoriesQuote")));
const RelatoriesLogs = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogs")));
const RelatoriesLogsSystem = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsSystem")));
const RelatoriesLogsUser = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsUser")));
const RelatoriesLogsBackoffice = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsBackoffice")));
const RelatoriesLogsWeb = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsWeb")));
const RelatoriesLogsSearch = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsSearch")));
const RelatoriesLogsMyAccount = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsMyAccount")));
const RelatoriesLogsCallCenter = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsCallCenter")));
const RelatoriesLogsAppIOS= Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsAppIOS")));
const RelatoriesLogsAndroid = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsAppAndroid")));
const RelatoriesLogsApi = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsApi")));
const RelatoriesLogsEngagement = Loadable(lazy(() => import("../views/Relatories/Logs/RelatoriesLogsEngagement")));
const RelatoriesAccess = Loadable(lazy(() => import("../views/Relatories/RelatoriesAccess")));
const Notifications = Loadable(lazy(() => import("../views/Notifications/Notifications")));
const CreateEditNotifications = Loadable(lazy(() => import("../views/Notifications/CreateEditNotification")));
const Groups = Loadable(lazy(() => import("../views/Groups/Groups")));
const CreateEditGroups = Loadable(lazy(() => import("../views/Groups/CreateEditGroups")));
const Access = Loadable(lazy(() => import("../views/Access/Access")));
const CreateEditAccess = Loadable(lazy(() => import("../views/Access/CreateEditAccess")));
const Frequencies = Loadable(lazy(() => import("../views/Frequencies/Frequencies")));
const CreateEditFrequencies = Loadable(lazy(() => import("../views/Frequencies/CreateEditFrequencie")));
const PaymentTypes = Loadable(lazy(() => import("../views/PaymentTypes/PaymentTypes")));
const CreateEditPaymentTypes = Loadable(lazy(() => import("../views/PaymentTypes/CreateEditPaymentType")));
const Signatures = Loadable(lazy(() => import("../views/Signatures/Signatures")));
const CreateSignatures = Loadable(lazy(() => import("../views/Signatures/CreateSignature")));
const Invoices = Loadable(lazy(() => import("../views/Invoices/Invoices")));
const CreateInvoices = Loadable(lazy(() => import("../views/Invoices/CreateInvoice")));
const BillsToReceive = Loadable(lazy(() => import("../views/BillsToReceive/BillsToReceive")));
const CreateEditBillsToReceive = Loadable(lazy(() => import("../views/BillsToReceive/CreateEditBillsToReceive")));
const BillsToPay = Loadable(lazy(() => import("../views/BillsToPay/BillsToPay")));
const CreateEditBillsToPay = Loadable(lazy(() => import("../views/BillsToPay/CreateEditBillsToPay")));
const Shopping = Loadable(lazy(() => import("../views/Shopping/Shopping")));
const Sales = Loadable(lazy(() => import("../views/Sales/Sales")));
const BillExpired = Loadable(lazy(() => import("../views/BillsExpired/BillsExpired")));
const FinancialReport = Loadable(lazy(() => import("../views/FinancialReport/FinancialReport")));
const FinancialDashboard = Loadable(lazy(() => import("../views/FinancialDashboard/FinancialDashboard")));
const FinancialSignaturesReport = Loadable(lazy(() => import("../views/Signatures/PdfReports")));
const FinancialInvoicesReport = Loadable(lazy(() => import("../views/Invoices/PdfReports")));
const FinancialReportPDF = Loadable(lazy(() => import("../views/FinancialReport/PdfReports")));
const Receipt = Loadable(lazy(() => import("../views/Receipt/Receipt")));
const Contract = Loadable(lazy(() => import("../views/Contract/Contract")));
const Plans = Loadable(lazy(() => import("../views/Plans/Plans")));
const CreateEditPlans = Loadable(lazy(() => import("../views/Plans/CreateEditPlan")));
const ContractModels = Loadable(lazy(() => import("../views/ContractModels/ContractModels")));
const CreateEditContractModels = Loadable(lazy(() => import("../views/ContractModels/CreateEditContractModel")));
const DetailInvoice = Loadable(lazy(() => import("../views/DetailInvoice/DetailInvoice")));
const Permissions = Loadable(lazy(() => import("../views/Permissions/Permissions")));
const CreateEditPermission = Loadable(lazy(() => import("../views/Permissions/CreateEditPermission")));
const UserPermissions = Loadable(lazy(() => import("../views/Users/UserPermissions")));
const PermissionGroups = Loadable(lazy(() => import("../views/PermissionGroups/PermissionGroups")));
const CreateEditPermissionGroup = Loadable(lazy(() => import("../views/PermissionGroups/CreateEditGroup")));
const CategoriesProductsServices = Loadable(lazy(() => import("../views/CategoriesProductsServices/CategoryProductsServices")));
const CreateEditCategoryProductsServices = Loadable(lazy(() => import("../views/CategoriesProductsServices/CreateEditCategory")));
const RelatorieBirthdays = Loadable(lazy(() => import("../views/Relatories/RelatorieBirthdays")));
const PDFBirthdays = Loadable(lazy(() => import("../views/Relatories/BirthdaysPDF")));
const Gallery = Loadable(lazy(() => import("../views/Gallery/Gallery")));
const EditGallery = Loadable(lazy(() => import("../views/Gallery/EditGallery")));

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));
const Login = Loadable(lazy(() => import("../views/auth/Login")));

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/call-center", exact: true, element: <CallCenter /> },
      { path: "/profile", exact: true, element: <Profile /> },
      { path: "/companies", exact: true, element: <Companies /> },
      { path: "/create-edit-company", exact: true, element: <CreateEditCompany />},
      { path: "/companies-testimonials/:id", exact: true, element: <CompaniesTestimonials /> },
      { path: "/companies-statistics", exact: true, element: <CompaniesStatistics /> },
      { path: "/segments", exact: true, element: <Segements /> },
      { path: "/create-edit-segments", exact: true, element: <CreateEditSegment />},
      { path: "/users", exact: true, element: <Users /> },
      { path: "/create-edit-users", exact: true, element: <CreateEditUsers /> },
      { path: "/detail-users/:id", exact: true, element: <DetailUsers /> },
      { path: "/jobs", exact: true, element: <Jobs /> },
      { path: "/create-edit-jobs", exact: true, element: <CreateEditJobs /> },
      { path: "/detail-jobs/:id", exact: true, element: <DetailJobs /> },
      { path: "/real-states", exact: true, element: <RealStates /> },
      { path: "/create-edit-real-states", exact: true, element: <CreateEditRealStates />},
      { path: "/detail-real-states/:id", exact: true, element: <DetailRealStates />},
      { path: "/real-states-category", exact: true, element: <CategoryRealStates />},
      { path: "/create-edit-real-states-category", exact: true, element: <CreateEditRealStatesCategory />},
      { path: "/vehicles", exact: true, element: <Vehicles /> },
      { path: "/create-edit-vehicles", exact: true, element: <CreateEditVehicles />},
      { path: "/detail-vehicles/:id", exact: true, element: <DetailVehicles />},
      { path: "/news", exact: true, element: <News /> },
      { path: "/create-edit-news", exact: true, element: <CreateEditNews /> },
      { path: "/detail-news/:id", exact: true, element: <DetailNews /> },
      { path: "/events", exact: true, element: <Events /> },
      { path: "/create-edit-events", exact: true, element: <CreateEditEvents />},
      { path: "/detail-events/:id", exact: true, element: <DetailEvents /> },
      { path: "/courses", exact: true, element: <Courses /> },
      { path: "/create-edit-courses", exact: true, element: <CreateEditCourses />},
      { path: "/detail-courses/:id", exact: true, element: <DetailCourses /> },
      { path: "/courses-category", exact: true, element: <CategoryCourses /> },
      { path: "/create-edit-courses-category", exact: true, element: <CreateEditCoursesCategory />},
      { path: "/products", exact: true, element: <Products /> },
      { path: "/create-edit-products", exact: true, element: <CreateEditProducts />},
      { path: "/detail-products/:id", exact: true, element: <DetailProducts />},
      { path: "/create-edit-services", exact: true, element: <CreateEditServices />},
      { path: "/detail-services/:id", exact: true, element: <DetailServices />},
      { path: "/services", exact: true, element: <Services /> },
      { path: "/categories-products-services", exact: true, element: <CategoriesProductsServices /> },
      { path: "/create-edit-category-products-services", exact: true, element: <CreateEditCategoryProductsServices />},
      { path: "/offers", exact: true, element: <Offers /> },
      { path: "/offers-coupons", exact: true, element: <OffersCoupons /> },
      { path: "/create-edit-offers", exact: true, element: <CreateEditOffers />},
      { path: "/detail-offers/:id", exact: true, element: <DetailOffers /> },
      { path: "/highlights", exact: true, element: <Highlights /> },
      { path: "/create-edit-highlights", exact: true, element: <CreateEditHighlights />},
      { path: "/detail-highlights/:id", exact: true, element: <DetailHighlights />},
      { path: "/posts", exact: true, element: <Posts /> },
      { path: "/create-edit-posts", exact: true, element: <CreateEditPosts /> },
      { path: "/detail-posts/:id", exact: true, element: <DetailPosts /> },
      { path: "/interests", exact: true, element: <Interests /> },
      { path: "/create-edit-interests", exact: true, element: <CreateEditInterests />},
      { path: "/my-business", exact: true, element: <MyBusiness /> },
      { path: "/pages", exact: true, element: <Pages /> },
      { path: "/create-edit-pages", exact: true, element: <CreateEditPages /> },
      { path: "/banners", exact: true, element: <Banners /> },
      { path: "/create-edit-banners", exact: true, element: <CreateEditBanners />},
      { path: "/walls", exact: true, element: <Walls /> },
      { path: "/create-edit-walls", exact: true, element: <CreateEditWalls /> },
      { path: "/detail-walls/:id", exact: true, element: <DetailWalls /> },
      { path: "/notifications", exact: true, element: <Notifications /> },
      { path: "/create-edit-notifications", exact: true, element: <CreateEditNotifications /> },
      { path: "/admins", exact: true, element: <Admins /> },
      { path: "/create-edit-admins", exact: true, element: <CreateEditAdmins />},
      { path: "/detail-admins/:id", exact: true, element: <DetailAdmins /> },
      { path: "/cities", exact: true, element: <Cities /> },
      { path: "/create-cities", exact: true, element: <CreateCities /> },
      { path: "/states", exact: true, element: <States /> },
      { path: "/create-states", exact: true, element: <CreateStates /> },
      { path: "/leads", exact: true, element: <Leads /> },
      { path: "/detail-lead", exact: true, element: <DetailLeads /> },
      { path: "/edit-leads", exact: true, element: <EditLeads /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/create-edit-forms", exact: true, element: <CreateEditForms /> },
      { path: "/dictionary", exact: true, element: <Dictionary /> },
      { path: "/create-edit-dictionary", exact: true, element: <CreateEditDictionary /> },
      { path: "/lotteries", exact: true, element: <Lotteries /> },
      { path: "/create-edit-lotteries", exact: true, element: <CreateEditLotteries /> },
      { path: "/bio-links", exact: true, element: <BioLinks /> },
      { path: "/create-edit-bio-links", exact: true, element: <CreateEditBioLinks /> },
      { path: "/relatories-call-center", exact: true, element: <RelatoriesCallCenter /> },
      { path: "/relatories-call-center-quotes", exact: true, element: <RelatoriesQuote /> },
      { path: "/relatories-logs", exact: true, element: <RelatoriesLogs /> },
      { path: "/relatories-logs-system", exact: true, element: <RelatoriesLogsSystem /> },
      { path: "/relatories-logs-user", exact: true, element: <RelatoriesLogsUser /> },
      { path: "/relatories-logs-backoffice", exact: true, element: <RelatoriesLogsBackoffice /> },
      { path: "/relatories-logs-web", exact: true, element: <RelatoriesLogsWeb /> },
      { path: "/relatories-logs-search", exact: true, element: <RelatoriesLogsSearch /> },
      { path: "/relatories-logs-myaccount", exact: true, element: <RelatoriesLogsMyAccount /> },
      { path: "/relatories-logs-callcenter", exact: true, element: <RelatoriesLogsCallCenter /> },
      { path: "/relatories-logs-app-ios", exact: true, element: <RelatoriesLogsAppIOS /> },
      { path: "/relatories-logs-app-android", exact: true, element: <RelatoriesLogsAndroid/> },
      { path: "/relatories-logs-api", exact: true, element: <RelatoriesLogsApi/> },
      { path: "/relatories-logs-engagements", exact: true, element: <RelatoriesLogsEngagement/> },
      { path: "/relatories-access", exact: true, element: <RelatoriesAccess/> },
      { path: "/groups", exact: true, element: <Groups/> },
      { path: "/create-edit-groups", exact: true, element: <CreateEditGroups/> },
      { path: "/access", exact: true, element: <Access/> },
      { path: "/create-edit-access", exact: true, element: <CreateEditAccess/> },
      { path: "/frequencies", exact: true, element: <Frequencies/> },
      { path: "/create-edit-frequencies", exact: true, element: <CreateEditFrequencies/> },
      { path: "/payment-types", exact: true, element: <PaymentTypes/> },
      { path: "/create-edit-payment-types", exact: true, element: <CreateEditPaymentTypes/> },
      { path: "/signatures", exact: true, element: <Signatures/> },
      { path: "/create-signatures", exact: true, element: <CreateSignatures/> },
      { path: "/invoices", exact: true, element: <Invoices/> },
      { path: "/create-invoices", exact: true, element: <CreateInvoices/> },
      { path: "/ins", exact: true, element: <BillsToReceive/> },
      { path: "/create-edit-ins", exact: true, element: <CreateEditBillsToReceive/> },
      { path: "/outs", exact: true, element: <BillsToPay/> },
      { path: "/create-edit-outs", exact: true, element: <CreateEditBillsToPay/> },
      { path: "/shopping", exact: true, element: <Shopping/> },
      { path: "/sales", exact: true, element: <Sales/> },
      { path: "/bills-expired", exact: true, element: <BillExpired/> },
      { path: "/financial-report", exact: true, element: <FinancialReport/> },
      { path: "/financial-dashboard", exact: true, element: <FinancialDashboard/> },
      { path: "/plans", exact: true, element: <Plans/> },
      { path: "/create-edit-plan", exact: true, element: <CreateEditPlans/> },
      { path: "/contract-models", exact: true, element: <ContractModels/> },
      { path: "/create-edit-contract-model", exact: true, element: <CreateEditContractModels/> },
      { path: "/permissions", exact: true, element: <Permissions/> },
      { path: "/create-edit-permission", exact: true, element: <CreateEditPermission/> },
      { path: "/user-permissions", exact: true, element: <UserPermissions/> },
      { path: "/permission-groups", exact: true, element: <PermissionGroups/> },
      { path: "/create-edit-permission-group", exact: true, element: <CreateEditPermissionGroup/> },
      { path: "/birthdays", exact: true, element: <RelatorieBirthdays/> },
      { path: "/gallery", exact: true, element: <Gallery/> },
      { path: "/edit-gallery", exact: true, element: <EditGallery/> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/reports",
    element: <BlankLayout />,
    children: [
      { path: "financial/signatures-report", exact: true, element: <FinancialSignaturesReport/> },
      { path: "financial/contract", exact: true, element: <Contract/> },
      { path: "financial/invoices-report", exact: true, element: <FinancialInvoicesReport/> },
      { path: "financial/report", exact: true, element: <FinancialReportPDF/> },
      { path: "financial/report", exact: true, element: <FinancialReportPDF/> },
    ],
  },
  {
    path: "/birthdays",
    element: <BlankLayout />,
    children: [
      { path: "report", exact: true, element: <PDFBirthdays/> },
    ],
  },
  {
    path: "/receipt",
    element: <BlankLayout />,
    children: [
      { path: "/receipt", exact: true, element: <Receipt/> },
    ],
  },
  {
    path: "/detail-invoice",
    element: <BlankLayout />,
    children: [
      { path: "/detail-invoice", exact: true, element: <DetailInvoice/> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
