import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  callToken: '',
  selection: [],
};

export const CallCenterSlice = createSlice({
  name: "callCenter",
  initialState,
  reducers: {
    SetCallToken: (state, action) => {
      state.callToken = action.payload;
    },
    SetSelection: (state, action) => {
      state.selection = [...action.payload];
    },
    ToggleSelection: (state, action) => {
      const set = new Set(state.selection);

      if (set.has(action.payload)) {
        set.delete(action.payload);
      } else {
        set.add(action.payload);
      }
  
      state.selection = [...set];
    },
    ClearSelection: (state) => {
      state.selection = [];
    }
  },
});

export const {
  SetCallToken,
  SetSelection,
  ToggleSelection,
  ClearSelection,
} = CallCenterSlice.actions;

export default CallCenterSlice.reducer;
