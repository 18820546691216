import { createContext, useEffect, useState, useMemo } from 'react';
import useApi from "../services/api";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const api = useApi();
  const [user, setUser] = useState(null);

  const userProfile = useMemo(() => user?.data || {}, [user]);

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
    
  //   if (token) {
  //     refreshUserProfile();
  //     return;
  //   }

  // }, []);

  const refreshUserProfile = async () => {
    const userResult = await api.getCurrentUser()
    if(userResult.metadata.result >= 1){
      setUser(userResult)
    } else {
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userProfile,
        refreshUserProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}