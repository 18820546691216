import { id } from "date-fns/locale"

// const baseUrl = 'https://api2.novainfortel.dev.br/v1'

const request = async (method, endpoint, params, token = null, isFormData = false) => {
  method = method.toLowerCase()
  let fullUrl = `${process.env.REACT_APP_BASE_URL}${endpoint}`
  let body = isFormData ? params : null
  let headers = {}
  switch (method) {
    case 'get':
      const queryString = new URLSearchParams(params).toString()
      fullUrl += `?${queryString}`
      break
    case 'post':
    case 'put':
    case 'patch':
    case 'delete':
      body = isFormData ? params : JSON.stringify(params)
      break
  }
  if (!isFormData) {
    headers = { 'Content-Type': 'application/json' }
  }
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  // const req = await fetch(fullUrl, { method, headers, body })
  const req = await fetch(fullUrl, { method, headers, body })
  const json = await req.json()
  return json
}

export default () => {
  return {
    getToken: () => {
      return localStorage.getItem('token')
    },
    registerLog: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/sys/log', data, token)
      return json
    },
    validadeToken: async () => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/auth/validate', {}, token)
      return json
    },
    login: async (email, password) => {
      const json = await request('post', '/auth/login?source=backoffice', { email, password })
      return json
    },
    logout: async () => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/auth/logout?source=backoffice', {}, token)
      localStorage.removeItem('token')
      return json
    },
    //Gravar Log
    registerLog: async (data) => {
      // {
      //   "source": "api",
      //   "module": "companies",
      //   "module_item_id": null,
      //   "method": "get",
      //   "action": "search",
      //   "group": "user.logs",
      //   "description": "descrição do log (cliente)",
      //   "raw": "descrição técnica do log (desenvolvedor)",
      //   "id_user": null
      // }
      const token = localStorage.getItem('token')
      const json = await request('post', '/sys/log', {...data}, token)
      return json
    },
    //Integração com IA
    integrationIA: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/sys/ai', {...data}, token)
      return json
    },
    //IBot
    sendIBot: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/sys/wbot', {...data}, token)
      return json
    },
    //Current User
    getCurrentUser: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', '/user?source=backoffice', {}, token)
      return json
    },
    updateCurrentUser: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/user?source=backoffice`, {...data}, token)
      return json
    },
    updateCurrentUserAvatar: async (formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/user/avatar?source=backoffice`, formData, token, true)
      return json
    },
    //Sys Files
    postFile: async (/*id,*/ formData) => {
      const token = localStorage.getItem('token')
      // const json = await request('post', `/companies/${id}/media`, formData, token, true)
      const json = await request('post', `/sys/files`, formData, token, true)
      return json
    },
    getFiles: async (type, id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/files`, {id, type}, token)
      return json
    },
    changeFeaturedFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/sys/files/${id}/featured`, {}, token)
      return json
    },
    deleteFiles: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/sys/files/${id}`, {}, token)
      return json
    },
    //Sys Getways
    postGateway: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/sys/gateways`, {...data}, token)
      return json
    },
    getGatewaysByCompany: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/gateways/list-by-companie-id/${id}`, {}, token)
      return json
    },
    updateGateway: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/sys/gateways/${id}`, {...data}, token)
      return json
    },
    deleteGateway: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/sys/gateways/${id}`, {}, token)
      return json
    },
    //Estatisticas
    getReports: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/reports/daily-access`, {...params}, token)
      return json
    },
    getReportsMonth: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/reports/month-access`, {...params}, token)
      return json
    },
    //Segments
    getSegments: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/segments`, {...params}, token)
      return json
    },
    getSegmentsSearch: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/segments/search/${params.search}`, {...params}, token)
      return json
    },
    getSegmentsSearchAsyncSelect: async (search, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/segments/search/${search}`, {...params}, token)
      return json
    },
    getSpecificSegment: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/segments/${id}?source=backoffice`, {}, token)
      return json
    },
    createSegment: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/segments?source=backoffice', {...data}, token)
      return json
    },
    updateSegment: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/segments/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteSegment: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/segments/${id}?source=backoffice`, {}, token)
      return json
    },
    getFilesSegment: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/segments/${id}/media`, {}, token)
      return json
    },
    uploadFileSegment: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/segments/${id}/media`, formData, token, true)
      return json
    },
    deleteSegmentFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/segments/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    //Lista as Cities
    getAllCities: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/citys`, {}, token)
      return json
    },
    getCitiesByState: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/citys/list-by-state-id/${id}`, {}, token)
      return json
    },
    //Lista os States
    getStates: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/states`, {}, token)
      return json
    },
    //Lista os Bancos
    getBanks: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/banks`, {}, token)
      return json
    },
    //Highlights
    getHighlights: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/highlights/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificHighlight: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/highlights/${id}?source=backoffice`, {}, token)
      return json
    },
    createHighlight: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/highlights?source=backoffice', {...data}, token)
      return json
    },
    updateHighlight: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/highlights/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteHighlight: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/highlights/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusHighlight: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/highlights/${id}/status`, {}, token)
      return json
    },
    getFilesHighlight: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/highlights/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileHighlight: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/highlights/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteHighlightFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/highlights/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoHighlights: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/highlights/${id}/seo`, {}, token)
      return json
    },
    updateSeoHighlights: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/highlights/${id}/seo`, {...data}, token)
      return json
    },
    getHighlightsStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/highlights/status`, {}, token)
      return json
    },
    getHighlightsStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/highlights/date`, {}, token)
      return json
    },
    getHighlightsStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/highlights/status/city/${city}`, {}, token)
      return json
    },
    getHighlightsStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/highlights/date/city/${city}`, {}, token)
      return json
    },
    //Posts
    getPosts: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/posts/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificPost: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/posts/${id}?source=backoffice`, {}, token)
      return json
    },
    createPost: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/posts?source=backoffice', {...data}, token)
      return json
    },
    updatePost: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/posts/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deletePost: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/posts/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusPost: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/posts/${id}/status`, {}, token)
      return json
    },
    getFilesPost: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/posts/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFilePost: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/posts/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deletePostFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/posts/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoPost: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/posts/${id}/seo`, {}, token)
      return json
    },
    updateSeoPost: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/posts/${id}/seo`, {...data}, token)
      return json
    },
    getPostsStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/posts/status`, {}, token)
      return json
    },
    getPostsStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/posts/date`, {}, token)
      return json
    },
    getPostsStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/posts/status/city/${city}`, {}, token)
      return json
    },
    getPostsStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/posts/date/city/${city}`, {}, token)
      return json
    },
    //Offers
    getOffers: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/offers/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificOffer: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/offers/${id}?source=backoffice`, {}, token)
      return json
    },
    getVouchersOffer: async (code) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/offers/voucher/${code}?source=backoffice`, {}, token)
      return json
    },
    createOffer: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/offers?source=backoffice', {...data}, token)
      return json
    },
    updateOffer: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/offers/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteOffer: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/offers/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusOffer: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/offers/${id}/status`, {}, token)
      return json
    },
    getFilesOffer: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/offers/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileOffer: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/offers/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteOfferFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/offers/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoOffer: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/offers/${id}/seo`, {}, token)
      return json
    },
    updateSeoOffer: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/offers/${id}/seo`, {...data}, token)
      return json
    },
    getOffersStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/offers/status`, {}, token)
      return json
    },
    getOffersStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/offers/date`, {}, token)
      return json
    },
    getOffersStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/offers/status/city/${city}`, {}, token)
      return json
    },
    getOffersStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/offers/date/city/${city}`, {}, token)
      return json
    },
    getVoucherById: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/offers/voucher/${id}`, {}, token)
      return json
    },
    //Products Services
    getProductsServicesCategories: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/products-and-services/categories/list`, { ...params }, token)
      return json
    },
    createProductServiceCategory: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/products-and-services/categories', {...data}, token)
      return json
    },
    getProductsServices: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/products-and-services/list/${cityId}`, { ...params }, token)
      return json
    },
    getProductsServicesSearchTerm: async (search, city, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/products-and-services/search/${search}?id_city=${city}`, { ...params}, token)
      return json
    },
    getSpecificProductService: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/products-and-services/${id}?source=backoffice`, {}, token)
      return json
    },
    createProductService: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/products-and-services?source=backoffice', {...data}, token)
      return json
    },
    changeStatusProductService: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/products-and-services/${id}/status`, {}, token)
      return json
    },
    updateProductService: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/products-and-services/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteProductService: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/products-and-services/${id}?source=backoffice`, { }, token)
      return json
    },
    getFilesProductService: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/products-and-services/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileProductService: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/products-and-services/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteProductServiceFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/products-and-services/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoProductService: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/products-and-services/${id}/seo`, {}, token)
      return json
    },
    updateSeoProductService: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/products-and-services/${id}/seo?`, {...data}, token)
      return json
    },
    getProductsServicesStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/products-and-services/status`, {}, token)
      return json
    },
    getProductsServicesStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/products-and-services/date`, {}, token)
      return json
    },
    getProductsServicesStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/products-and-services/status/city/${city}`, {}, token)
      return json
    },
    getProductsServicesStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/products-and-services/date/city/${city}`, {}, token)
      return json
    },
    //Courses
    getCourses: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/courses/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificCourse: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/courses/${id}?source=backoffice`, {}, token)
      return json
    },
    getCoursesSearchTerm: async (search, city, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/courses/search/${search}?id_city=${city}`, {...params}, token)
      return json
    },
    createCourse: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/courses', {...data}, token)
      // const json = await request('post', '/courses?source=backoffice', {...data}, token)
      return json
    },
    changeStatusCourse: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/courses/${id}/status`, {}, token)
      return json
    },
    updateCourse: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/courses/${id}`, {...data}, token)
      // const json = await request('put', `/courses/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteCourse: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/courses/${id}?source=backoffice`, { }, token)
      return json
    },
    getFilesCourse: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/courses/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileCourse: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/courses/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteCourseFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/courses/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoCourse: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/courses/${id}/seo`, {}, token)
      return json
    },
    updateSeoCourse: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/courses/${id}/seo`, {...data}, token)
      return json
    },
    getCoursesStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/courses/status`, {}, token)
      return json
    },
    getCoursesStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/courses/date`, {}, token)
      return json
    },
    getCoursesStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/courses/status/city/${city}`, {}, token)
      return json
    },
    getCoursesStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/courses/date/city/${city}`, {}, token)
      return json
    },
    //Events
    getEvents: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/events/list/${cityId}`, { ...params }, token)
      return json
    },
    getEventsSearchTerm: async (search, city, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/events/search/${search}?id_city=${city}`, {...params}, token)
      return json
    },
    getSpecificEvent: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/events/${id}?source=backoffice`, {}, token)
      return json
    },
    createEvent: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/events', {...data}, token)
      // const json = await request('post', '/events?source=backoffice', {...data}, token)
      return json
    },
    updateEvent: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/events/${id}`, {...data}, token)
      // const json = await request('put', `/events/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteEvent: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/events/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusEvent: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/events/${id}/status`, {}, token)
      return json
    },
    getFilesEvent: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/events/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileEvent: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/events/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteEventFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/events/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoEvents: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/events/${id}/seo`, {}, token)
      return json
    },
    updateSeoEvents: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/events/${id}/seo`, {...data}, token)
      return json
    },
    getEventsStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/events/status`, {}, token)
      return json
    },
    getEventsStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/events/date`, {}, token)
      return json
    },
    getEventsStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/events/status/city/${city}`, {}, token)
      return json
    },
    getEventsStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/events/date/city/${city}`, {}, token)
      return json
    },
    //Gallery
    getGalleries: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/gallery/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificGallery: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/gallery/${id}`, {}, token)
      return json
    },
    createGallery: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/gallery', {...data}, token)
      return json
    },
    updateGallery: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/gallery/${id}`, {...data}, token)
      return json
    },
    deleteGallery: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/gallery/${id}`, {}, token)
      return json
    },
    changeStatusGallery: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/gallery/${id}/status`, {}, token)
      return json
    },
    getFilesGallery: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/gallery/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileGallery: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/gallery/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteGalleryFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/gallery/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoGallery: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/gallery/${id}/seo`, {}, token)
      return json
    },
    updateSeoGallery: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/gallery/${id}/seo`, {...data}, token)
      return json
    },
    //News
    getNews: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/news/list/${cityId}`, { ...params }, token)
      return json
    },
    getNewsSearchTerm: async (search, city, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/news/search/${search}?id_city=${city}`, {...params}, token)
      return json
    },
    getSpecificNews: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/news/${id}?source=backoffice`, {}, token)
      return json
    },
    createNews: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/news', {...data}, token)
      // const json = await request('post', '/news?source=backoffice', {...data}, token)
      return json
    },
    updateNews: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/news/${id}`, {...data}, token)
      // const json = await request('put', `/news/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteNews: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/news/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusNews: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/news/${id}/status`, {}, token)
      return json
    },
    getFilesNews: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/news/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileNews: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/news/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteNewsFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/news/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoNews: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/news/${id}/seo`, {}, token)
      return json
    },
    updateSeoNews: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/news/${id}/seo`, {...data}, token)
      return json
    },
    getNewsStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/news/status`, {}, token)
      return json
    },
    getNewsStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/news/date`, {}, token)
      return json
    },
    getNewsStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/news/status/city/${city}`, {}, token)
      return json
    },
    getNewsStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/news/date/city/${city}`, {}, token)
      return json
    },
    //Vehicles
    getVehicles: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/vehicles/list/${cityId}`, { ...params }, token)
      return json
    },
    getVehiclesSearchTerm: async (search, city, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/vehicles/search/${search}?id_city=${city}`, {...params}, token)
      return json
    },
    getSpecificVehicle: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/vehicles/${id}?source=backoffice`, {}, token)
      return json
    },
    createVehicle: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/vehicles', {...data}, token)
      // const json = await request('post', '/vehicles?source=backoffice', {...data}, token)
      return json
    },
    updateVehicle: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/vehicles/${id}`, {...data}, token)
      // const json = await request('put', `/vehicles/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteVehicle: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/vehicles/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusVehicle: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/vehicles/${id}/status`, {}, token)
      return json
    },
    getFilesVehicle: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/vehicles/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileVehicle: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/vehicles/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteVehicleFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/vehicles/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoVehicle: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/vehicles/${id}/seo`, {}, token)
      return json
    },
    updateSeoVehicle: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/vehicles/${id}/seo`, {...data}, token)
      return json
    },
    getVehiclesStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/vehicles/status`, {}, token)
      return json
    },
    getVehiclesStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/vehicles/date`, {}, token)
      return json
    },
    getVehiclesStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/vehicles/status/city/${city}`, {}, token)
      return json
    },
    getVehiclesStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/vehicles/date/city/${city}`, {}, token)
      return json
    },
    //RealStates
    getRealStates: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/real-states/list/${cityId}`, { ...params }, token)
      return json
    },
    getRealStatesSearchTerm: async (search, city, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/real-states/search/${search}?id_city=${city}`, {...params}, token)
      return json
    },
    getSpecificRealState: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/real-states/${id}?source=backoffice`, {}, token)
      return json
    },
    createRealState: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/real-states', {...data}, token)
      // const json = await request('post', '/real-states?source=backoffice', {...data}, token)
      return json
    },
    updateRealState: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/real-states/${id}`, {...data}, token)
      // const json = await request('put', `/real-states/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteRealState: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/real-states/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusRealState: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/real-states/${id}/status`, {}, token)
      return json
    },
    getFilesRealState: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/real-states/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileRealState: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/real-states/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteRealStateFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/real-states/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoRealState: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/real-states/${id}/seo`, {}, token)
      return json
    },
    updateSeoRealState: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/real-states/${id}/seo`, {...data}, token)
      return json
    },
    getRealStatesStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/real-states/status`, {}, token)
      return json
    },
    getRealStatesStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/real-states/date`, {}, token)
      return json
    },
    getRealStatesStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/real-state/status/city/${city}`, {}, token)
      return json
    },
    getRealStatesStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/real-state/date/city/${city}`, {}, token)
      return json
    },
    //JOBS
    getJobs: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/jobs/list/${cityId}`, { ...params }, token)
      return json
    },
    getJobsSearchTerm: async (search, city, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/jobs/search/${search}?city=${city}`, {...params}, token)
      return json
    },
    getSpecificJob: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/jobs/${id}?source=backoffice`, {}, token)
      return json
    },
    createJob: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/jobs', {...data}, token)
      // const json = await request('post', '/jobs?source=backoffice', {...data}, token)
      return json
    },
    updateJob: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/jobs/${id}`, {...data}, token)
      // const json = await request('put', `/jobs/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteJob: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/jobs/${id}?source=backoffice`, {}, token)
      return json
    },
    changeStatusJob: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/jobs/${id}/status`, {}, token)
      return json
    },
    getFilesJob: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/jobs/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileJob: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/jobs/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteJobFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/jobs/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    getSeoJob: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/jobs/${id}/seo`, {}, token)
      return json
    },
    updateSeoJob: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/jobs/${id}/seo`, {...data}, token)
      return json
    },
    getJobsStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/jobs/status`, {}, token)
      return json
    },
    getJobsStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/jobs/date`, {}, token)
      return json
    },
    getJobsStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/jobs/status/city/${city}`, {}, token)
      return json
    },
    getJobsStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/jobs/date/city/${city}`, {}, token)
      return json
    },
    //Users
    getUsers: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', '/user/list', {...params}, token)
      return json
    },
    getUsersSearch: async (search) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/search/${search}`, {}, token)
      return json
    },
    getUserById: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get',`/backoffice/users/${id}`, {}, token)
      return json
    },
    createUser: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/user', {...data}, token)
      return json
    },
    changeStatusUser: async (id, status) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/user/${id}/status`, { status }, token)
      return json
    },
    updateUser: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/user/${id}`, { ...data }, token)
      return json
    },
    deleteUser: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/user/${id}`, { }, token)
      return json
    },
    getUserCompanies: async (params, id) => {
      const token = localStorage.getItem('token')
      const json = await request('get',`/companies/user/${id}`, { ...params }, token)
      return json
    },
    //Company
    getCompanies: async (params, id) => {
      const token = localStorage.getItem('token')
      const json = await request('get',`/companies/list/${id}`, { ...params }, token)
      return json
    },
    getSpecificCompany: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get',`/companies/show/${id}`, {}, token)
      return json
    },
    getCompaniesAll: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get',`/companies/all/`, { ...params }, token)
      return json
    },
    getCompaniesSearch: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/companies/search/${params.search}`, {...params}, token)
      return json
    },
    getCompaniesSearchAsyncSelect: async (search,params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/companies/search/${search}`, {...params}, token)
      return json
    },
    getGeo: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post',`/companies/get-geo`, { address: data }, token)
      return json
    },
    createCompany: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/companies/create?source=backoffice', {...data}, token)
      return json
    },
    changeStatusCompany: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/companies/${id}/status`, {}, token)
      return json
    },
    updateCompany: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/companies/update/${id}?source=backoffice`, { ...data }, token)
      return json
    },
    linkUserCompany: async (idCompany, idUser) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/companies/update-owner/${idCompany}/${idUser}?source=backoffice`, {  }, token)
      return json
    },
    linkPlanCompany: async (idCompany, idPlan) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/companies/update-plan/${idCompany}/${idPlan}?source=backoffice`, {  }, token)
      return json
    },
    deleteCompany: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/companies/delete/${id}`, { }, token)
      return json
    },
    getFilesCompany: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/companies/${id}/media?source=backoffice`, {}, token)
      return json
    },
    deleteCompanyFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/companies/${id}/media/delete`, {}, token)
      return json
    },
    getSeoCompany: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/companies/${id}/seo`, {}, token)
      return json
    },
    updateSeoCompany: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/companies/${id}/seo`, {...data}, token)
      return json
    },
    getCompaniesStatisticStatus: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/companies/status`, {}, token)
      return json
    },
    getCompaniesStatisticDate: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/companies/date`, {}, token)
      return json
    },
    getCompaniesStatisticStatusCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/companies/status/city/${city}`, {}, token)
      return json
    },
    getCompaniesStatisticDateCity: async (city) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/statistics/companies/date/city/${city}`, {}, token)
      return json
    },
    //Depoimentos
    getCompanyTestimonials: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/testimonials/list-by-companie-id/${params.id_company}?source=backoffice`, {page: params.page}, token)
      return json
    },
    deleteTestimonial: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/testimonials/${id}`, {}, token)
      return json
    },
    changeStatusTestimonial: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/testimonials/${id}/status`, {}, token)
      return json
    },
    //Interesses
    getInterestsAll: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get',`/backoffice/interests/all`, { ...params }, token)
      return json
    },
    getSpecificInterest: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/interests/${id}`, {}, token)
      return json
    },
    getSearchInterests: async (search) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/interests/search/${search}`, {}, token)
      return json
    },
    createInterest: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/backoffice/interests', {...data}, token)
      return json
    },
    updateInterest: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/backoffice/interests/${id}`, {...data}, token)
      return json
    },
    deleteInterest: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/backoffice/interests/${id}`, { }, token)
      return json
    },
    //Páginas
    getPages: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/pages/list/${cityId}`, { ...params }, token)
      return json
    },
    getPagesSearchTerm: async (search) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/pages/search/${search}`, {}, token)
      return json
    },
    getSpecificPage: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/pages/${id}`, {}, token)
      return json
    },
    createPage: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/pages?source=backoffice', {...data}, token)
      return json
    },
    updatePage: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/pages/${id}`, {...data}, token)
      return json
    },
    deletePage: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/pages/${id}`, {}, token)
      return json
    },
    changeStatusPage: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/pages/${id}/status`, {}, token)
      return json
    },
    changeShowInPage: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/pages/${id}/show`, {}, token)
      return json
    },
    getFilesPage: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/pages/${id}/media`, {}, token)
      return json
    },
    uploadFilePage: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/pages/${id}/media`, formData, token, true)
      return json
    },
    deletePageFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/pages/${id}/media/delete`, {}, token)
      return json
    },
    getSeoPage: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/pages/${id}/seo`, {}, token)
      return json
    },
    updateSeoPage: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/pages/${id}/seo`, {...data}, token)
      return json
    },
    // Banners
    getBanners: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/banners/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificBanner: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/banners/${id}?source=backoffice`, {}, token)
      return json
    },
    createBanner: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/banners?source=backoffice', {...data}, token)
      return json
    },
    updateBanner: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/banners/${id}?source=backoffice`, {...data}, token)
      return json
    },
    changeStatusBanner: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/banners/${id}/status`, {}, token)
      return json
    },
    deleteBanner: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/banners/${id}`, { }, token)
      return json
    },
    getFilesBanner: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/banners/${id}/media?source=backoffice`, {}, token)
      return json
    },
    uploadFileBanner: async (id, formData) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/banners/${id}/media?source=backoffice`, formData, token, true)
      return json
    },
    deleteBannerFile: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/banners/${id}/media/delete?source=backoffice`, {}, token)
      return json
    },
    //Wall
    getWall: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/walls/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificWall: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/walls/${id}?source=backoffice`, {}, token)
      return json
    },
    createWall: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/walls?source=backoffice', {...data}, token)
      return json
    },
    changeStatusWall: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/walls/${id}/status`, {}, token)
      return json
    },
    updateWall: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/walls/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteWall: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/walls/${id}`, { }, token)
      return json
    },
    getSeoWall: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/walls/${id}/seo`, {}, token)
      return json
    },
    updateSeoWall: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/walls/${id}/seo`, {...data}, token)
      return json
    },
    //Notificações
    getNotifications: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/notifications/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificNotification: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/notifications/${id}?source=backoffice`, {}, token)
      return json
    },
    createNotification: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/notifications?source=backoffice', {...data}, token)
      return json
    },
    changeStatusNotification: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/notifications/${id}/status`, {}, token)
      return json
    },
    updateNotification: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/notifications/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteNotification: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/notifications/${id}`, { }, token)
      return json
    },
    //Leads
    getCrmList: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/crm/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificLead: async (id, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/crm/specific/${id}`, { ...params }, token)
      return json
    },
    getLeadMonitoring: async (params, traceId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/trace/${traceId}`, { ...params }, token)
      return json
    },
    createLead: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/crm/lead?source=backoffice', {...data}, token)
      return json
    },
    updateLead: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/crm/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteLead: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/crm/${id}?source=backoffic`, { }, token)
      return json
    },
    //Call Center
    startCall: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/backoffice/callcenter/start-call?source=callcenter', {...data}, token)
      return json
    },
    stopCall: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/backoffice/callcenter/stop-call?source=callcenter', {...data}, token)
      return json
    },
    updateCall: async (call_token, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/backoffice/callcenter/call/${call_token}?source=callcenter`, {...data}, token)
      return json
    },
    createQuote: async (cityId, data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/backoffice/callcenter/call/quote/${cityId}?source=callcenter`, {...data}, token)
      return json
    },
    getSearchCompanies: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/companies/search`, {...params}, token)
      return json
    },
    //Bio Links
    getBioLinks: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/bio-links/list/${cityId}`, { ...params }, token)
      return json
    },
    getSpecificBioLink: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/bio-links/${id}`, {}, token)
      return json
    },
    createBioLink: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/bio-links', {...data}, token)
      return json
    },
    changeStatusBioLink: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/bio-links/${id}/status`, {}, token)
      return json
    },
    updateBioLink: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/bio-links/${id}`, {...data}, token)
      return json
    },
    deleteBioLink: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/bio-links/${id}`, { }, token)
      return json
    },
    //Dicionário
    getDictionary: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/dictionary/all`, { page: params.page }, token)
      return json
    },
    getSpecificWordOnDictionary: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/dictionary/${id}`, {}, token)
      return json
    },
    getSearchTermDictionary: async (term) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/dictionary/search/${term}`, {}, token)
      return json
    },
    createWordOnDictionary: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/backoffice/dictionary', {...data}, token)
      return json
    },
    updateWordOnDictionary: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/backoffice/dictionary/${id}`, {...data}, token)
      return json
    },
    deleteWordOnDictionary: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/backoffice/dictionary/${id}`, {}, token)
      return json
    },
    //Loterias
    getLotteries: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/guia-lottery/all`, { ...params }, token)
      return json
    },
    getSpecificLottery: async (id, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/guia-lottery/${id}`, { ...params }, token)
      return json
    },
    getSearchLotteries:async (search, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/guia-lottery/search/${search}`, {...params}, token)
      return json
    },
    createLottery: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', '/backoffice/guia-lottery?source=backoffice', {...data}, token)
      return json
    },
    updateLottery: async (id,data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/backoffice/guia-lottery/${id}?source=backoffice`, {...data}, token)
      return json
    },
    deleteLottery: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/backoffice/guia-lottery/${id}?source=backoffice`, {}, token)
      return json
    },
    //Relatórios
    getRelatoriesCallCenter: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/callcenter/calls/${params.cityId}`, { page: params.page }, token)
      return json
    },
    getRelatoriesQuote: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/callcenter/call/quote/${cityId}`, { page: params.page }, token)
      return json
    },
    getRelatoriesLogs: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/sys/log`, { ...params }, token)
      return json
    },
    getSegmentsAudios: async (id, params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/segments/audios/${id}`, {...params}, token)
      return json
    },
    getRelatorieBirthday: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/relatories/birthdays`, {...params}, token)
      return json
    },
    //Financeiro
    getFrequencies: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/frequences/list`, {  }, token)
      return json
    },
    calculatePay: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/calculate`, { ...params }, token)
      return json
    },
    getPaymentTypes: async () => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/calculate/list`, { }, token)
      return json
    },
    updateParcelPayment: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/financial/payment/update/${id}`, {...data }, token)
      return json
    },
    getRelatorieFinancial: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/filter`, { ...params }, token)
      return json
    },
    getStatistics: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/statistics`, { ...params }, token)
      return json
    },
    getSignatures: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/signatures/list`, { ...params }, token)
      return json
    },
    getSpecificSignature: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/signatures/${id}`, {  }, token)
      return json
    },
    getSignaturesByCompany: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/signatures/company/${params.id}`, { page: params.page }, token)
      return json
    },
    getParcelBySignature: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/signatures/${params.id}/parcel`, { page: params.page }, token)
      return json
    },
    createSignature: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/financial/signatures/recorrences/create`, { ...data }, token)
      return json
    },
    deleteSignature: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/financial/signatures/recorrences/delete/${id}`, {}, token)
      return json
    },
    getInvoices: async (params, cityId) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/invoices/list`, { ...params }, token)
      return json
    },
    getSpecificInvoice: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/invoices/${id}`, {  }, token)
      return json
    },
    getInvoicesByCompany: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/invoices/company/${params.id}`, { page: params.page }, token)
      return json
    },
    getParcelByInvoice: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/invoices/${params.id}/parcel`, { page: params.page}, token)
      return json
    },
    createInvoice: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/financial/invoices/recorrences/create`, { ...data }, token)
      return json
    },
    deleteInvoice: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/financial/invoices/recorrences/delete/${id}`, {}, token)
      return json
    },
    getFinancial: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial`, { ...params }, token)
      return json
    },
    createReceivement: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/financial/receivement`, { ...data }, token)
      return json
    },
    updateReceivement: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/financial/receivement/${id}`, { ...data }, token)
      return json
    },
    getSpecificReceivement: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/receivement/${id}`, { }, token)
      return json
    },
    deleteReceivement: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/financial/receivement/${id}`, {}, token)
      return json
    },
    createPayment: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/financial/payment`, { ...data }, token)
      return json
    },
    updatePayment: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/financial/payment/${id}`, { ...data }, token)
      return json
    },
    getSpecificPayment: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/payment/${id}`, { }, token)
      return json
    },
    deletePayment: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/financial/payment/${id}`, {}, token)
      return json
    },
    downloadPaymentAccount: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/financial/payment/Download/${id}`, { }, token)
      return json
    },
    downloadReceivementAccount: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/financial/receivement/Download/${id}`, { }, token)
      return json
    },
    //Parcelas Financeiro
    getSpecificParcelSignature: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/signatures/parcel/${id}`, { }, token)
      return json
    },
    getSpecificParcelInvoice: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/financial/invoices/parcel/${id}`, { }, token)
      return json
    },
    //Planos
    getPlans: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/plans/all`, { ...params }, token)
      return json
    },
    createPlan: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/backoffice/plans`, { ...data }, token)
      return json
    },
    updatePlan: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/backoffice/plans/${id}`, { ...data }, token)
      return json
    },
    getSpecificPlanById: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/plans/${id}`, { }, token)
      return json
    },
    getSpecificPlanByHash: async (hash) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/plans/${hash}`, { }, token)
      return json
    },
    getPlanSearch: async (search) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/plans/${search}`, { }, token)
      return json
    },
    deletePlan: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/backoffice/plans/${id}`, {}, token)
      return json
    },
    //Grupos Planos
    getGroupPlans: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/backoffice/plans/groups/list`, { ...params }, token)
      return json
    },
    createGroupPlan: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/backoffice/plans/groups`, { ...data }, token)
      return json
    },
    //Permissoes
    getPermissions: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/permissions/list`, { ...params }, token)
      return json
    },
    getPermissionsGroups: async (params) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/permissions/groups/list`, { ...params }, token)
      return json
    },
    createPermission: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/user/permissions`, { ...data }, token)
      return json
    },
    updatePermission: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/user/permissions/${id}`, { ...data }, token)
      return json
    },
    deletePermission: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/user/permissions/${id}`, {}, token)
      return json
    },
    getSpecificPermissionById: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/permissions/${id}`, { }, token)
      return json
    },
    getListPermissionsByUser: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/permissions/list-by-user-id/${id}`, { }, token)
      return json
    },
    getPermissionsByUser: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/permissions/get-by-user-id/${id}`, { }, token)
      return json
    },
    updateUserPermissions: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/user/permissions/update`, { ...data }, token)
      return json
    },
    //Grupos de Permissão
    getSpecificPermissionByGroup: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/permissions/list-by-group-id/${id}`, { }, token)
      return json
    },
    createPermissionGroup: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/user/permissions/groups`, { ...data }, token)
      return json
    },
    updatePermissionGroup: async (id, data) => {
      const token = localStorage.getItem('token')
      const json = await request('put', `/user/permissions/groups/${id}`, { ...data }, token)
      return json
    },
    deletePermissionGroup: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('delete', `/user/permissions/groups/${id}`, {}, token)
      return json
    },
    getSpecificPermissionGroupById: async (id) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/user/permissions/groups/${id}`, { }, token)
      return json
    },
    //Payment Parcel
    getTransactionDetail: async (module, idItem) => {
      const token = localStorage.getItem('token')
      const json = await request('get', `/transactions/module/parcel/${idItem}`, { }, token)
      return json
    },
    createTransaction: async (data) => {
      const token = localStorage.getItem('token')
      const json = await request('post', `/payments/pec`, { ...data }, token)
      return json
    },
  }
}
