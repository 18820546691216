import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  city: {},
};

export const GlobalsSlice = createSlice({
  name: "globals",
  initialState,
  reducers: {
    SetCity: (state, action) => {
      state.city = action.payload;
    },
  },
});

export const {
  SetCity,
} = GlobalsSlice.actions;

export default GlobalsSlice.reducer;
